import { Link, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import InstagramIcon from "@mui/icons-material/Instagram"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"

interface IFooterProps {
  handlePageChange: (page: string) => void
}

const Footer = (props: IFooterProps) => {
  const { handlePageChange } = props

  const openLink = () => {
    window.open(
      "https://www.instagram.com/obatcha_pazan?igshid=MmlzYWVINDQ5Yg==",
    )
  }

  const openWhatsApp = () => {
    window.open("https://wa.me/4917685963758")
  }

  const handleAddressClick = () => {
    window.open(
      "https://www.google.de/maps/place/Ulmenstra%C3%9Fe+102,+40476+D%C3%BCsseldorf/@51.2484803,6.780001,17z/data=!3m1!4b1!4m6!3m5!1s0x47b8c9ecd2a314b9:0x8c5cc2cb18bab8aa!8m2!3d51.2484803!4d6.7825759!16s%2Fg%2F11bw43q1x4?entry=ttu",
    )
  }

  return (
    <Grid2
      sx={{
        //  position: "sticky",
        bottom: 0,
        bgcolor: "primary.main",
      }}
      container
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-around"
      minHeight="4em"
      alignItems={{ xs: "left", md: "center" }}
      rowGap={5}
      px={{ xs: 2, sm: 12 }}
      py={5}
    >
      <Grid2 container direction={"column"} gap={{ xs: 1, md: 2 }}>
        <Grid2 color="white">
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
            }}
            onClick={() => handlePageChange("imprint")}
          >
            {"Impressum"}
          </Typography>
        </Grid2>
        <Grid2 color="white">
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
            }}
            onClick={() => handlePageChange("legalNote")}
          >
            {"Datenschutz-Richtlinien"}
          </Typography>
        </Grid2>
        <Grid2 color="white" alignSelf={"left"}>
          <InstagramIcon
            fontSize={"large"}
            onClick={openLink}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
            }}
          />
          <WhatsAppIcon
            fontSize={"large"}
            onClick={openWhatsApp}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
            }}
          />
        </Grid2>
      </Grid2>

      <Grid2 container direction={"column"} gap={{ xs: 1, md: 2 }}>
        <Grid2 color="white">
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
              "& a": {
                textDecoration: "none",
              },
            }}
          >
            <a href="tel:+4917685963758">{"Fon: +49 176 859 63758"}</a>
          </Typography>
        </Grid2>
        <Grid2 color="white">
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
              "& a": {
                textDecoration: "none",
              },
            }}
          >
            <a href="mailto:Eduard.Lotz@obatcha-pazan-service.de">
              {"E-Mail: Eduard.Lotz@obatcha-pazan-service.de"}
            </a>
          </Typography>
        </Grid2>
        <Grid2 color="white">
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "gray",
              },
              "& a": {
                textDecoration: "none",
              },
            }}
          >
            <a href="/">{"Internet: www.obatcha-pazan-service.de"}</a>
          </Typography>
        </Grid2>
      </Grid2>

      <Grid2
        container
        direction={"column"}
        gap={{ xs: 1, md: 2 }}
        onClick={handleAddressClick}
        sx={{
          cursor: "pointer",
          "&:hover p": {
            color: "gray",
          },
        }}
      >
        <Grid2 color="white">
          <Typography>{"Obatcha & Pazan Service GmbH"}</Typography>
        </Grid2>
        <Grid2 color="white">
          <Typography>{"Ulmenstraße 102"}</Typography>
        </Grid2>
        <Grid2 color="white" alignSelf={"left"}>
          <Typography>{"D-40476 Düsseldorf"}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default Footer
