import React from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserRouter } from "react-router-dom"
import ReactDOM from "react-dom/client"
import "./index.css"
import { CssBaseline } from "@mui/material"
import App from "./App"
import "./i18n"
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    {/* <Suspense fallback="loading"> */}
    <BrowserRouter>
      <CssBaseline>
        <App />
      </CssBaseline>
    </BrowserRouter>
    {/* </Suspense> */}
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
