import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import AdbIcon from "@mui/icons-material/Adb"
import { NavHashLink } from "react-router-hash-link"
import { SvgIcon } from "@mui/material"
// import { ReactComponent as Logo } from "../../assets/logo.svg"
import Logo from "../../assets/logo_cropped.png"

const pages = ["aboutUs", "customers", "applicant", "interest"]
const hashLinks = [
  { title: "Über uns", link: "aboutUs" },
  { title: "Kunden", link: "customers" },
  { title: "Bewerber", link: "applicant" },
  { title: "Interesse", link: "interest" },
]
// const settings = ["Profile", "Account", "Dashboard", "Logout"]

interface INavigationBarProps {
  handlePageChange: (page: string) => void
}

const NavigationBar = (props: INavigationBarProps) => {
  const { handlePageChange } = props
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const onLogoClick = () => {
    handlePageChange("main")
    window.scrollTo(0, 0)
  }

  return (
    <AppBar
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "primary.main",
        flexDirection: "row",
        minHeight: "5em",
      }}
    >
      <Box
        width={"max-content"}
        onClick={onLogoClick}
        sx={{ cursor: "pointer" }}
        px={1}
        py={1}
        // position="absolute"
        // top={10}
        // left={10}
        // zIndex={10}
      >
        <img src={Logo} style={{ height: "4em" }} alt="Logo" />
      </Box>
      <Container maxWidth="xl" style={{ alignSelf: "center" }}>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", sm: "flex" }, mr: 1 }} /> */}
          {/* <Logo width={"2em"} height={"2em"} /> */}
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
          {/* Burgermenü */}
          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}
            justifyContent={"end"}
          >
            <IconButton
              size="large"
              // aria-label="account of current user"
              // aria-controls="menu-appbar"
              // aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              {hashLinks.map((page) => (
                <MenuItem key={page.link} onClick={handleCloseNavMenu}>
                  <NavHashLink
                    smooth
                    style={{ color: "unset", textDecoration: "unset" }}
                    // activeStyle={{ fontWeight: "bold" }}
                    to={`/#${page.link}`}
                    onClick={() => handlePageChange("main")}
                  >
                    <Typography textAlign="center" textTransform={"uppercase"}>
                      {page.title}
                    </Typography>
                  </NavHashLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", sm: "none" }, mr: 1 }} /> */}
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}
          >
            {hashLinks.map((page) => (
              <Button
                key={page.link}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontSize: "1.25rem",
                }}
              >
                <NavHashLink
                  style={{ color: "unset", textDecoration: "unset" }}
                  smooth
                  // activeStyle={{ fontWeight: "bold" }}
                  to={`/#${page.link}`}
                  onClick={() => handlePageChange("main")}
                >
                  {page.title}
                </NavHashLink>
              </Button>
            ))}
          </Box>
          {/* 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
           ttings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavigationBar
