import "./App.css"
import { Box, ThemeProvider } from "@mui/material"
// import { useTranslation } from "react-i18next"

import { useState } from "react"
import theme from "./assets/theme"
import NavigationBar from "./components/navigationBar/NavigationBar.component"
import Footer from "./components/footer/Footer.component"
import ContentContainer from "./components/contentContainer/ContentContainer.component"
import Imprint from "./components/imprint/Imprint.component"
import LegalNote from "./components/legalNote/LegalNote.component"

const pages = ["main", "imprint", "legalNote"]

function App() {
  // const { t } = useTranslation()

  const [activePage, setActivePage] = useState(pages[0])

  const handlePageChange = (page: string) => {
    setActivePage(page)
  }

  return (
    <ThemeProvider theme={theme}>
      <NavigationBar handlePageChange={handlePageChange} />
      {activePage === pages[0] && (
        <ContentContainer handlePageChange={handlePageChange} />
      )}
      {activePage === pages[1] && <Imprint />}
      {activePage === pages[2] && <LegalNote />}
      <Footer handlePageChange={handlePageChange} />
    </ThemeProvider>
  )
}

export default App
