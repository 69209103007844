import { createTheme } from "@mui/material"

const theme = createTheme({
  palette: {
    primary: {
      main: "#1e2024",
      // main: "#37464f",
      light: "#282c34",
      dark: "#141414",
    },
    secondary: {
      main: "#c93000",
      light: "#ff6433",
      dark: "#900000",
    },
  },
})

export default theme
