import { Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { useEffect } from "react"

const Imprint = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid2
      container
      justifyContent="left"
      textAlign="center"
      sx={{
        background: "linear-gradient(45deg, #141414 30%, #282c34 75%)",
        color: "#ffffff",
        minHeight: "calc(100vh - 14em)",
      }}
    >
      <Grid2 px={{ xs: 2, sm: 12 }} py={5}>
        <Grid2 container direction={"column"} textAlign={"left"}>
          <Typography variant="h4" pb={2}>
            {"Impressum"}
          </Typography>
          <Typography fontWeight={"bold"}>
            {"Obatcha & Pazan Service GmbH"}
          </Typography>
          <Typography>{"Ulmenstraße 102"}</Typography>
          <Typography pb={5}>{"D-40476 Düsseldorf"}</Typography>
          <Typography display={"inline-flex"} gap={1}>
            <Typography fontWeight={"bold"}>{"Fon:"}</Typography>
            <Typography>{"+49 176 859 63758"}</Typography>
          </Typography>
          <Typography display={"inline-flex"} gap={1}>
            <Typography fontWeight={"bold"} whiteSpace={"nowrap"}>
              {"E-Mail:"}
            </Typography>
            <Typography>{"Eduard.Lotz@obatcha-pazan-service.de"}</Typography>
          </Typography>
          <Typography display={"inline-flex"} gap={1} pb={5}>
            <Typography fontWeight={"bold"} whiteSpace={"nowrap"}>
              {"Internet:"}
            </Typography>
            <Typography>{"www.obatcha-pazan-service.de"}</Typography>
          </Typography>
          <Typography whiteSpace={{ xs: "break-spaces", sm: "initial" }}>
            {"Vertretungsberechtigter Geschäftsführer: \nEduard Lotz"}
          </Typography>
          <Typography>{"Registergericht: Amtsgericht Köln"}</Typography>
          <Typography>{"Registernummer: HRB 113660"}</Typography>
          <Typography pb={5}>
            {
              "Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE 359356023"
            }
          </Typography>
          <Typography>
            {
              "Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links."
            }
          </Typography>
          <Typography>
            {
              "Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich."
            }
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default Imprint
