import { Box, Divider, List, ListItem, Paper, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { Route } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link"
import backGroundImage from "../../assets/images/welcome_cropped.png"
import logoDrinks from "../../assets/logo_Drinks.png"

interface IContentContainerProps {
  handlePageChange: (page: string) => void
}

const ContentContainer = (props: IContentContainerProps) => {
  const { handlePageChange } = props
  return (
    <Grid2
      container
      justifyContent="center"
      textAlign="center"
      sx={{
        background: "linear-gradient(45deg, #141414 30%, #282c34 75%)",
        // background: "linear-gradient(45deg, #141414 30%, #333333 90%)",
        color: "#ffffff",
      }}
    >
      <Grid2 container xs={12}>
        <Grid2
          container
          direction="column"
          justifyContent="center"
          sx={{
            width: "100%",
            height: "50vw",
            backgroundImage: `url(${backGroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <Grid2>
            <Typography
              sx={{
                typography: {
                  xs: "h5",
                  sm: "h4",
                  md: "h3",
                  lg: "h2",
                  xl: "h1",
                },
              }}
              color="white"
              bgcolor="#00000080"
            >
              Obatcha & Pazan Service GmbH
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        container
        width={{ xs: "90%", md: "60%", lg: "50%" }}
        justifyContent="center"
        // gap={4}
        sx={{ padding: "3rem 0", marginBottom: "3rem" }}
      >
        <Box
          id="aboutUs"
          sx={{
            width: "100%",
            height: { xs: "5rem", sm: "12rem", md: "20rem", lg: "25rem" },
            backgroundImage: `url(${logoDrinks})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
          mb={4}
        />
        <Grid2>
          <Typography
            variant="h2"
            textTransform={"uppercase"}
            sx={{
              paddingBottom: "1rem",
              color: "#ffffff",
              fontWeight: "bold",
              typography: {
                xs: "h4",
                lg: "h2",
              },
            }}
          >
            Über uns
          </Typography>
        </Grid2>

        <Grid2>
          <Typography
            variant="h6"
            sx={{
              typography: {
                xs: "body1",
                lg: "h5",
              },
            }}
          >
            Obatcha & Pazan Service GmbH steht für Qualität, Zuverlässigkeit und
            Flexibilität in der Gastronomie-Personaldienstleistung. Als ein
            dynamisch wachsendes Unternehmen sind wir stolz darauf, ein breites
            Netzwerk von hochqualifizierten Fachkräften anzubieten und
            individuelle Lösungen bereitzustellen, die perfekt auf die
            Anforderungen unserer Kunden zugeschnitten sind. Wir legen großen
            Wert auf die sorgfältige Auswahl und Schulung unseres Personals, um
            sicherzustellen, dass wir in jeder Situation den bestmöglichen
            Service bieten können.
          </Typography>
        </Grid2>
        <Grid2 xs={12} px={10} my={13} id="customers">
          <Divider sx={{ borderBottomWidth: 2, borderColor: "#ffffff80" }} />
        </Grid2>
        <Grid2>
          <Typography
            textTransform={"uppercase"}
            variant="h3"
            sx={{
              paddingBottom: "1rem",
              color: "#ffffff",
              fontWeight: "bold",
              typography: {
                xs: "h4",
                lg: "h2",
              },
            }}
          >
            Für Kunden
          </Typography>
        </Grid2>

        <Grid2>
          <Typography
            variant="h6"
            sx={{
              typography: {
                xs: "body1",
                lg: "h5",
              },
            }}
          >
            Wir verstehen, dass die Personalsuche zeitaufwendig und mühsam sein
            kann. Hier kommt Obatcha & Pazan Service GmbH ins Spiel: Wir nehmen
            Ihnen diese Aufgabe ab und präsentieren Ihnen geeignete Kandidaten,
            die genau Ihren Anforderungen entsprechen. Sie profitieren nicht nur
            von unserer umfangreichen Datenbank qualifizierter Fachkräfte,
            sondern auch von unserer Fähigkeit, flexibel auf saisonale Spitzen,
            Veranstaltungen oder kurzfristige Personalengpässe zu reagieren.
            Darüber hinaus ermöglicht unsere Dienstleistung eine effizientere
            Kostenkontrolle, da Sie nur für die tatsächlich geleisteten
            Arbeitsstunden zahlen.
          </Typography>
        </Grid2>
        <Grid2 xs={12} px={10} my={13} id="applicant">
          <Divider sx={{ borderBottomWidth: 2, borderColor: "#ffffff80" }} />
        </Grid2>
        <Grid2>
          <Typography
            textTransform={"uppercase"}
            variant="h3"
            sx={{
              paddingBottom: "1rem",
              color: "#ffffff",
              fontWeight: "bold",
              typography: {
                xs: "h4",
                lg: "h2",
              },
            }}
          >
            Für Bewerber
          </Typography>
        </Grid2>
        <Grid2>
          <List
            sx={{
              listStyleType: "disc",
              pl: 2,
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <Typography
                variant="h6"
                sx={{
                  typography: {
                    xs: "body1",
                    lg: "h5",
                  },
                }}
              >
                Breite Auswahl: Bei Obatcha & Pazan Service GmbH bieten wir Dir
                eine Vielzahl von Möglichkeiten in der Gastronomiebranche.
                Servicekraft, Serviceleitung, Barkeeper oder Thekenkraft, wir
                haben die richtige Position für Dich.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography
                variant="h6"
                sx={{
                  typography: {
                    xs: "body1",
                    lg: "h5",
                  },
                }}
              >
                Passgenau: Wir helfen Dir, eine Position zu finden, die Deinen
                Fähigkeiten und Interessen entspricht, um Deine Fähigkeiten zu
                erweitern und neue Erfahrungen zu sammeln.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography
                variant="h6"
                sx={{
                  typography: {
                    xs: "body1",
                    lg: "h5",
                  },
                }}
              >
                Aufgaben: Professionelle und freundliche Bedienung der Gäste.
                Gewährleistung eines reibungslosen Ablaufs im Servicebereich.
                Unterstützung des Teams bei der Vorbereitung und Durchführung
                von Veranstaltungen.
              </Typography>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Typography
                variant="h6"
                sx={{
                  typography: {
                    xs: "body1",
                    lg: "h5",
                  },
                }}
              >
                Teil eines Teams: Werde Teil unseres dynamischen Teams und
                erlebe die Vielfalt der Gastronomiebranche. Wir bieten Dir
                flexible Arbeitszeiten und eine überdurchschnittliche Bezahlung,
                inklusive Trinkgeldern.
              </Typography>
            </ListItem>
          </List>
        </Grid2>
        <Grid2 xs={12} px={10} my={13} id="interest">
          <Divider sx={{ borderBottomWidth: 2, borderColor: "#ffffff80" }} />
        </Grid2>
        <Grid2>
          <Typography
            textTransform={"uppercase"}
            variant="h3"
            sx={{
              paddingBottom: "1rem",
              color: "#ffffff",
              fontWeight: "bold",
              typography: {
                xs: "h4",
                lg: "h2",
              },
            }}
          >
            Haben wir dein Interesse geweckt?
          </Typography>
        </Grid2>
        <Grid2>
          <Typography
            variant="h6"
            sx={{
              typography: {
                xs: "body1",
                lg: "h5",
              },
            }}
          >
            Wir bei Obatcha & Pazan Service GmbH freuen uns immer darauf, von
            Dir hören. Egal, ob Du eine Frage hast, eine Beratung oder einfach
            mehr über unsere Dienstleistungen erfahren möchtest, wir sind hier,
            um Dir zu helfen. Du kannst uns telefonisch, per E-Mail oder über
            Whatsapp erreichen. Unser engagiertes Team steht bereit, um Deine
            Anfragen zu beantworten und Dir den erstklassigen Service zu bieten,
            den Du von Obatcha & Pazan Service GmbH erwartest.
          </Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  )
}

export default ContentContainer

// import { Box, Divider, List, ListItem, Paper, Typography } from "@mui/material"
// import Grid2 from "@mui/material/Unstable_Grid2"
// import { NavHashLink } from "react-router-hash-link"
// // import { HashLink } from "react-router-hash-link"
// import backGroundImage from "../../assets/images/welcome.jpg"

// const ContentContainer = () => {
//   return (
//     <Grid2 container justifyContent="center" textAlign="center">
//       <Grid2 container xs={12}>
//         <Grid2
//           container
//           direction="column"
//           justifyContent="center"
//           // sx={{ width: "100%", backgroundImage: `url(${backGroundImage})` }}
//           sx={{
//             width: "100%",
//             height: "68vw",
//             // backgroundSize: "cover",
//             backgroundImage: `url(${backGroundImage})`,
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "contain",
//           }}
//         >
//           <Grid2
//           // mx={10}
//           >
//             <Typography
//               sx={{
//                 typography: {
//                   xs: "body1",
//                   sm: "h3",
//                   md: "h4",
//                   lg: "h3",
//                   xl: "h2",
//                 },
//               }}
//               color="white"
//               bgcolor="#00000080"
//             >
//               Obatcha & Pazan Service GmbH
//             </Typography>
//           </Grid2>
//         </Grid2>
//       </Grid2>
//       <Grid2 container width={"70%"} justifyContent="center" gap={4}>
//         <Grid2>
//           <Typography variant="h3">Über uns</Typography>
//         </Grid2>

//         <Grid2>
//           <Typography variant="h6">
//             Obatcha & Pazan Service GmbH steht für Qualität, Zuverlässigkeit und
//             Flexibilität in der Gastronomie-Personaldienstleistung. Als ein
//             dynamisch wachsendes Unternehmen sind wir stolz darauf, ein breites
//             Netzwerk von hochqualifizierten Fachkräften anzubieten und
//             individuelle Lösungen bereitzustellen, die perfekt auf die
//             Anforderungen unserer Kunden zugeschnitten sind. Wir legen großen
//             Wert auf die sorgfältige Auswahl und Schulung unseres Personals, um
//             sicherzustellen, dass wir in jeder Situation den bestmöglichen
//             Service bieten können.
//           </Typography>
//         </Grid2>
//         <Grid2 xs={12} px={10} my={5}>
//           <Divider sx={{ borderBottomWidth: 2 }} />
//         </Grid2>
//         <Grid2>
//           <Typography variant="h3">Für Kunden</Typography>
//         </Grid2>
//         <Grid2>
//           <Typography variant="h6">
//             Wir verstehen, dass die Personalsuche zeitaufwendig und mühsam sein
//             kann. Hier kommt Obatcha & Pazan Service GmbH ins Spiel: Wir nehmen
//             Ihnen diese Aufgabe ab und präsentieren Ihnen geeignete Kandidaten,
//             die genau Ihren Anforderungen entsprechen. Sie profitieren nicht nur
//             von unserer umfangreichen Datenbank qualifizierter Fachkräfte,
//             sondern auch von unserer Fähigkeit, flexibel auf saisonale Spitzen,
//             Veranstaltungen oder kurzfristige Personalengpässe zu reagieren.
//             Darüber hinaus ermöglicht unsere Dienstleistung eine effizientere
//             Kostenkontrolle, da Sie nur für die tatsächlich geleisteten
//             Arbeitsstunden zahlen.
//           </Typography>
//         </Grid2>
//         <Grid2 xs={12} px={10} my={5}>
//           <Divider sx={{ borderBottomWidth: 2 }} />
//         </Grid2>
//         <Grid2>
//           <Typography variant="h3">Für Bewerber</Typography>
//         </Grid2>
//         <Grid2 px={10}>
//           <List
//             sx={{
//               listStyleType: "disc",
//               pl: 2,
//             }}
//           >
//             <ListItem sx={{ display: "list-item" }}>
//               <Typography variant="h6">
//                 Breite Auswahl: Bei Obatcha & Pazan Service GmbH bieten wir
//                 Ihnen eine Vielzahl von Möglichkeiten in der Gastronomiebranche.
//               </Typography>
//             </ListItem>
//             <ListItem sx={{ display: "list-item" }}>
//               <Typography variant="h6">
//                 Passgenau: Wir helfen Ihnen, eine Position zu finden, die Ihren
//                 Fähigkeiten und Interessen entspricht.
//               </Typography>
//             </ListItem>
//             <ListItem sx={{ display: "list-item" }}>
//               <Typography variant="h6">
//                 Investition in Ihre Karriere: Bei uns stehen Ihre
//                 Weiterentwicklung und Karrierefortschritte im Mittelpunkt. Wir
//                 bieten Möglichkeiten zur Weiterbildung und persönlichen
//                 Entwicklung.
//               </Typography>
//             </ListItem>
//             <ListItem sx={{ display: "list-item" }}>
//               <Typography variant="h6">
//                 Teil eines Teams: Werden Sie Teil unseres dynamischen Teams und
//                 erleben Sie die Vielfalt der Gastronomiebranche.
//               </Typography>
//             </ListItem>
//           </List>
//           {/* <Typography variant="h6">
//           Obatcha & Pazan Service GmbH ist nicht nur ein hervorragender Partner
//           für Unternehmen, sondern auch für Sie als Bewerber. Wir bieten eine
//           Vielzahl von Möglichkeiten in der Gastronomiebranche und helfen Ihnen,
//           eine Position zu finden, die Ihren Fähigkeiten und Interessen
//           entspricht. Bei uns stehen Sie im Mittelpunkt: Wir investieren in Ihre
//           Weiterbildung und unterstützen Sie dabei, Ihre Karriere in der
//           Gastronomie voranzutreiben. Werden Sie Teil unseres Teams und erleben
//           Sie die Vielfalt und Dynamik der Gastronomiebranche.
//         </Typography> */}
//         </Grid2>
//         <Grid2 xs={12} px={10} my={5}>
//           <Divider sx={{ borderBottomWidth: 2 }} />
//         </Grid2>
//         <Grid2>
//           <Typography variant="h3">
//             Haben wir dein Interesse geweckt?
//           </Typography>
//         </Grid2>
//         <Grid2>
//           <Typography variant="h6">
//             Wir bei Obatcha & Pazan Service GmbH freuen uns immer darauf, von
//             Ihnen zu hören. Egal, ob Sie eine Frage haben, eine Beratung
//             wünschen oder einfach mehr über unsere Dienstleistungen erfahren
//             möchten, wir sind hier, um Ihnen zu helfen. Sie können uns
//             telefonisch, per E-Mail oder über unser Kontaktformular auf der
//             Website erreichen. Unser engagiertes Team steht bereit, um Ihre
//             Anfragen zu beantworten und Ihnen den erstklassigen Service zu
//             bieten, den Sie von Obatcha & Pazan Service GmbH erwarten.
//           </Typography>
//         </Grid2>
//       </Grid2>
//     </Grid2>
//   )
// }

// export default ContentContainer
